import React, { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Disclosure } from '@headlessui/react';
import { MinusSmIcon, PlusSmIcon } from '@heroicons/react/solid';
import { Product } from '@Types/product/Product';
import { Facet } from '@Types/result/Facet';
import { GiThermometerCold } from 'react-icons/gi';
import { URLParam } from 'helpers/utils/updateURLParams';
import { useProducts } from 'frontastic';
import { useFormat } from '../../../helpers/hooks/useFormat';
import { getLabel, formatFacetValue } from 'helpers/utils/attributesHelpers';

type CheckboxDisclosureProps = {
  facetKey: string;
  updateParams: (params: URLParam[], facetKey: string) => void;
  products: Product[];
  facets: (Facet & { terms?: any[] })[];
};

const CheckboxDisclosure: React.FC<CheckboxDisclosureProps> = ({ facetKey, updateParams, products, facets }) => {
  const router = useRouter();
  const locale = router.locale
  const { categories } = useProducts();
  const { formatMessage: formatFacetMessage } = useFormat({ name: 'product' });
  const facetCurrent = facets.find((facet) => facet.identifier === facetKey);
  const [categoriesMap, setCategoriesMap] = useState({});

  const [terms, setTerms] = useState<any[]>(facetCurrent?.terms);

  useEffect(() => {
    if (facetCurrent?.terms?.length) {
      const tempTerms = [...facetCurrent.terms];
      for (let i = 0; i < facetCurrent.terms.length; i++) {
        const identifier = router?.query[`facets[${facetKey}][terms][${i}]`];
        if (!!identifier) {
          const item = tempTerms.find((tempItem) => tempItem.identifier === identifier);
          if (item) {
            item.selected = true;
          }
        }
      }
      const isNumeric = (string) => string == Number.parseFloat(string)
      tempTerms.sort((a, b) => false === isNumeric(a.label) ? a.label.localeCompare(b.label) : a.label - b.label);
      setTerms(tempTerms);
    } else {
      setTerms([]);
    }
  }, [router?.query]);

  const updateSelected = (i: number) => {
    const termsValue = terms.map((term, index) => {
      if (index === i) {
        return {
          ...term,
          selected: !term.selected,
        };
      }
      return term;
    });
    setTerms(termsValue);
    
    updateParams(
      termsValue
        .filter((term) => term.selected)
        .map((term, i) => ({ key: `facets[${facetKey}][terms][${i}]`, value: term.identifier })),
      facetKey
    );
  };
  useEffect(() => {
    if (!!categories?.length) {
      setCategoriesMap(
        categories.reduce((prev, curr) => {
          prev[curr.categoryId] = curr.name;
          return prev;
        }, {}),
      );
    }
  }, [categories]);

  return (
    <div>
      {!!terms?.length && (
        <div className="pb-6">
          <Disclosure defaultOpen>
            {({ open }) => (
              <>
                <Disclosure.Button className="filter-title">
                  <span className="">
                    {getLabel(facetKey, locale)}
                  </span>
                  <span className="ml-6 flex items-center">
                    {open ? (
                      <MinusSmIcon className="h-5 w-5" aria-hidden="true" />
                    ) : (
                      <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
                <Disclosure.Panel className="grid gap-2 pt-6">
                  {!!terms?.length &&
                    terms.map((term, i) => (
                      <label className="" key={term.identifier}>
                        <input
                          id={term.identifier}
                          name={term.identifier}
                          type="checkbox"
                          className="input input-checkbox mr-2"
                          onChange={() => updateSelected(i)}
                          checked={term.selected}
                        />
                        {formatFacetValue(facetKey, term.identifier)} ({term.count})
                      </label>
                    ))}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      )}
    </div>
  );
};

export default CheckboxDisclosure;
