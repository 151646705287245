import { useState } from 'react';
import { useFormat } from 'helpers/hooks/useFormat';
import { BusinessUnit } from "@Types/business-unit/BusinessUnit";
import { Cart } from '@Types/cart/Cart';
import { hasBasePath } from 'next/dist/server/router';

interface Props {
    readonly cart: Cart,
    readonly businessUnit: BusinessUnit
}
const BulkLoading = ({cart, businessUnit}: Props) => {
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const [buHasLimitation, setBuHasLimitation] = useState(businessUnit.custom?.fields?.blSkuRestriction.length > 0);
    const [buSkuLimit, setBuSkuLimitation] = useState(businessUnit.custom.fields.blSkuRestriction);
    const [bu20FtLimit, setBu20FtLimit] = useState(businessUnit.custom.fields.bl20FtVolume);
    const [bu40FtLimit, setBu40FtLimit] = useState(businessUnit.custom.fields.bl40FtVolume);

    let isEnabled = businessUnit.custom?.fields.blEnabled;
    function allProductsAreEligible(): boolean {
        if (false === buHasLimitation) {
            return true;
        }
        const skus = [];
        cart.lineItems.forEach((lineItem) => skus.push(lineItem.variant.sku));
        
        return skus.every((value) => buSkuLimit.includes(value));
    }

    function allProductsHaveSameVolumeMax(): boolean {
        if (false === buHasLimitation) {
            return true;
        }
        const uniqProduct20Fit = getUniq20FitForProducts();
        const uniqProduct40Fit = getUniq40FitForProducts();

        return uniqProduct20Fit.length === 1 && uniqProduct40Fit.length === 1;
    }

    function getMaxVolumeFor20Ft() {
        if (false === buHasLimitation) {
            return bu20FtLimit;
        }

        return getUniq20FitForProducts()[0];
    }

    function getMaxVolumeFor40Ft() {
        if (false === buHasLimitation) {
            return bu40FtLimit;
        }

        return getUniq40FitForProducts()[0];
    }

    function getUniq20FitForProducts() {
        const product20Fit = [];
        cart.lineItems.forEach((lineItem) => {
            product20Fit.push(lineItem.variant?.attributes?.['twenty_ft_volume']);
        });
        return [...new Set(product20Fit)];
    }

    function getUniq40FitForProducts() {
        const product40Fit = [];
        cart.lineItems.forEach((lineItem) => {
            product40Fit.push(lineItem.variant?.attributes?.['fourty_ft_volume']);
        });
        return [...new Set(product40Fit)];
    }

    // Max volumes are in m3
    let max20Ft = getMaxVolumeFor20Ft();
    let max40Ft = getMaxVolumeFor40Ft();
    if (max20Ft === undefined || max40Ft===undefined) {
        isEnabled = false;
    }

    // totalVol is in dm3
    let totalVol = 0;
    cart.lineItems.forEach((lineItem) => {
        let vol = ((lineItem.count/(lineItem.variant.attributes['number_of_sku_by_outer'] ?? 1))*(lineItem.variant.attributes['outer_volume'] ?? 0));
        totalVol = totalVol + vol;
    });

    totalVol = totalVol / 1000;
    let rawTotal20FtContainers = totalVol/max20Ft;
    let rawTotal40FtContainers = totalVol/max40Ft;

    let total40FtContainers = rawTotal40FtContainers - Math.floor(rawTotal40FtContainers) < 0.5
        ? Math.floor(rawTotal40FtContainers)
        : rawTotal40FtContainers;
    let total20FtContainers = total40FtContainers%1 === 0 ? Math.abs(rawTotal20FtContainers) - Math.floor(rawTotal20FtContainers) : 0;
    return ( isEnabled && (false === buHasLimitation || (true === allProductsAreEligible() && true === allProductsHaveSameVolumeMax())) && 
    <dl className="space-y-4 py-5">
        <div className="flex items-center justify-between">
            <dt className='font-bold'>{formatCartMessage({ id: 'order.bulk_loading', defaultMessage: 'Bulkloading' })}</dt>
        </div>
        <div className="flex items-center justify-between">
            <dt>{formatCartMessage({ id: 'order.bl_containerization_total_20th', defaultMessage: 'Total 20Ft. container(s) utilization for bulk loading' })}</dt>
            <dd className="whitespace-nowrap">{total20FtContainers===0.00 && total20FtContainers.toFixed(0) || total20FtContainers.toFixed(2)}</dd>
        </div>
        <div className="flex items-center justify-between">
            <dt>{formatCartMessage({ id: 'order.bl_containerization_total_40th', defaultMessage: 'Total 40Ft. container(s) utilization for bulk loading' })}</dt>
            <dd className="whitespace-nowrap">{total40FtContainers===0.00 && total40FtContainers.toFixed(0) || total40FtContainers.toFixed(2)}</dd>
        </div>
        <div className="flex items-center justify-between">
            <div className="text-gray-400">
                {formatCartMessage({ id: 'order.bulk_loading_info', defaultMessage: 'The bulk loading is calculated based on the volume of outers per established quantity, and if not all products are eligible, it will not be visible.' })}
            </div>
        </div>
    </dl>
    );
}

export default BulkLoading;